import ClipboardJS from 'clipboard';

export function getQueryString(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}

export function copyToClipboard(text,event,obj) {
    const clipboard = new ClipboardJS(event.target, {
        text: () => text
        });
    clipboard.on('success', () => {
        obj.$message({message: '复制成功',type: 'success'});
        clipboard.destroy();
    });
    clipboard.on('error', () => {
        obj.$message({message: '复制失败',type: 'error'});
        clipboard.destroy();
    });
    clipboard.onClick(event); // 解决！！
}

export function toTFLink(id,obj) {
    return 'https://testflight.apple.com/v1/invite/'+obj.tfInfo[`tf${id}_link`]
}

export function formatDate (timeStr) {
    if(timeStr == null || timeStr == 0) {
        return '暂未激活';
    }
    const date = new Date(timeStr * 1000);
    return date.toLocaleString()
}

 

import {formatDate} from "@/utils/common"

/**
 * 得到应用信息
 */
export function getAppInfo (obj) {
    obj.$http.get("down/redeem/" + obj.appName).then(response => {
        var data = response.data
        if (data.code === 1) {
            obj.installAppName = data.data.name;
            obj.installAppIcon = data.data.icon;
        } else {
            obj.$message({
                showClose: true,
                message: data.msg,
                type: 'error'
            });
            obj.initApp();
        }
    }).catch(error => {
        obj.$message({
            showClose: true,
            message: error.response.data.msg,
            type: 'error'
        });
        obj.initApp();
    }).finally(() => obj.loading = false)
} 

/**
 * 激活 
 * */
export function activateApp (obj) {
    obj.drawer = false;
    obj.$refs.formData.validate((valid) => {
        if (!valid) return
    })
    const appName = obj.appName; // 应用ID
    const vKey = obj.formData.authcode; // 授权码
    if (appName === '' || vKey === '') {
        return
    }
    obj.$http.get("down/redeem/"+appName+"/code/"+ vKey ).then(response => {
        var data = response.data
        if (data.code === 1) {
            obj.drawer = true;
            obj.redeemStatus = data.data.state == 1 ? "已激活" : data.data.state === 0 ? "未激活" : "已冻结";
            obj.authcode = vKey;
            obj.tfInfo = data.data;
            obj.redeemUsedAt = formatDate(data.data.active_time);
        }else {
            obj.$message.error(data.msg)
            obj.initApp();
        }
    }).catch(error => {
        obj.$message.error(error.response.data.msg)
        obj.initApp();
    }).finally(() => obj.loading = false)
}
<template>
    <el-drawer
    title="查询结果"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    size="90%"
    >
    <el-main style="padding:0 20px;">
        <div class="result_p">
            <p>兑换码：{{authcode}}</p>
            <p>激活状态：{{redeemStatus}}</p>
            <p  v-if="tfInfo.tf1_link != ''">应用1：
                <span @click="(event) => handleClipboard(1, event)" data-id="1">
                    <el-input style="width:70%" readonly :value="'https://testflight.apple.com/v1/invite/'+tfInfo.tf1_link" size="medium" >
                        <template slot="append">复制</template>
                    </el-input>
                </span>
            </p>
            <p  v-if="tfInfo.tf2_link != ''">应用2：
                <span @click="(event) => handleClipboard(2, event)" data-id="2">
                    <el-input style="width:70%" readonly :value="'https://testflight.apple.com/v1/invite/'+tfInfo.tf2_link" size="medium" >
                        <template slot="append">复制</template>
                    </el-input>
                </span>
            </p>
            <p  v-if="tfInfo.tf3_link != ''">应用3：
                <span @click="(event) => handleClipboard(3, event)" data-id="3">
                    <el-input style="width:70%" readonly :value="'https://testflight.apple.com/v1/invite/'+tfInfo.tf3_link" size="medium" >
                        <template slot="append">复制</template>
                    </el-input>
                </span>
            </p>
            <p v-if="tfInfo.active_time > 0">激活时间：{{redeemUsedAt}}</p>
        </div>
        <el-alert
            title="安装说明"
            description="为避免不必要的麻烦，请按以下步骤进行安装"
            type="warning"
            :closable="false"
            :style="{marginTop:'40px'}"
            show-icon>
        </el-alert>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>必装软件</span>
            </div>
            <div>
                <el-button plain class="btn-line" @click="toTestFlight">
                    <div style="display: flex; align-items: center;">
                        <img src="https://is4-ssl.mzstatic.com/image/thumb/Purple126/v4/f8/4f/8a/f84f8a44-88d2-b7e0-b915-a8e06159ae0f/AppIcon-85-220-0-4-2x.png/246x0w.png" style="width:20px"/>&nbsp;&nbsp;
                        <span>安装 Testflight</span>
                    </div>
                </el-button>
            </div>
            <p class="n-p">*如已安装 TestFlight，请进行第二步操作</p>
        </el-card>
        <el-alert
            title="开始安装应用"
            description="请选择对应的应用点击安装"
            :closable="false"
            type="success"
            :style="{marginTop:'30px'}"
            show-icon>
        </el-alert>
        <el-card class="box-card" v-if="tfInfo.tf1_name != ''">
            <div slot="header" class="clearfix">
                <span>应用1</span>
            </div>
            <div>
                <el-button plain class="btn-line" @click="toInstall(1)">
                    <div style="display: flex; align-items: center;">
                        <img :src="tfInfo.tf1_icon" style="width:20px"/>&nbsp;&nbsp;
                        <span>安装 {{tfInfo.tf1_name}}</span>
                    </div>
                </el-button>
            </div>
            <p class="n-p">*如点击按钮未跳转，请确认是否已安装 Testflight </p>
        </el-card>
        <el-card class="box-card" v-if="tfInfo.tf2_name != ''">
            <div slot="header" class="clearfix">
                <span>应用2</span>
            </div>
            <div>
                <el-button plain class="btn-line" @click="toInstall(2)">
                    <div style="display: flex; align-items: center;">
                        <img :src="tfInfo.tf2_icon" style="width:20px"/>&nbsp;&nbsp;
                        <span>安装 {{tfInfo.tf2_name}}</span>
                    </div>
                </el-button>
            </div>
            <p class="n-p">*如点击按钮未跳转，请确认是否已安装 Testflight </p>
        </el-card>
        <el-card class="box-card" v-if="tfInfo.tf3_name != ''">
            <div slot="header" class="clearfix">
                <span>应用3</span>
            </div>
            <div>
                <el-button plain class="btn-line" @click="toInstall(3)">
                    <div style="display: flex; align-items: center;">
                        <img :src="tfInfo.tf3_icon" style="width:20px"/>&nbsp;&nbsp;
                        <span>安装 {{tfInfo.tf3_name}}</span>
                    </div>
                </el-button>
            </div>
            <p class="n-p">*如点击按钮未跳转，请确认是否已安装 Testflight </p>
        </el-card>

    </el-main>
    </el-drawer>
  </template>
  
  <script>
  import {copyToClipboard,toTFLink} from "@/utils/common.js"
  export default {
    name: 'ActiveCode',
    props: {
        appName : {
            type: String,
            required: true
        },
        drawer: {
            type: Boolean,
            required: true
        },
        redeemStatus: {
            type: String,
            required: true
        },
        authcode: {
            type: String,
            required: true
        },
        redeemUsedAt: {
            type: String,
            required: true
        },
        tfInfo: {
            type: Object,
            required: true
        }
    },
    data() {
      return {
        show: true,
        direction: 'btt',
      };
    },
    methods: {
        handleClose: function() {
            this.$emit('close-draw')
            //done();
        }, 
        toTestFlight: function() {
            window.location.href = 'https://apps.apple.com/cn/app/testflight/id899247664'
        },
        toInstall: function(number) {
            const appName = this.appName
            const vKey = this.authcode
            var numUrl = "down/redeem/"+appName+"/code/"+ vKey + "/v"+number
            this.$http.post(numUrl).then(response => {
                var data = response.data
                if (data.code === 1) {
                    this.drawer = true;
                    this.tfInfo[`tf${number}_link`] = data.data.url;
                    window.location.href = 'itms-beta://beta.itunes.apple.com/v1/invite/'+data.data.url
                }else {
                    this.$message.error(data.msg)
                    this.initApp();
                }
            }).catch(error => {
                this.$message.error(error.response.data.msg)
                this.initApp();
            }).finally(() => this.loading = false)
        },
        handleClipboard: function(id,event) {
            copyToClipboard(toTFLink(id,this),event,this)
        },
    }
  };
  </script>
  
  <style scoped>
  h1 {
    color: blue;
  }
  </style>